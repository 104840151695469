<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            :multiple="multiple"
            no-title
            show-week
            scrollable
            :type="type"
        >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
                Annulla
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    // props: ['formDates', 'label', 'multiple', 'disabled'],
    props: {
        formDates: {
            type: [Array, String],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: "date"
        }
    },

    data: () => ({
        menu: false
    }),

    computed: {
        date: {
            get() {
                return this.formDates;
            },
            set(newValue) {
                this.$emit("update:formDates", newValue);
            }
        }
    }
};
</script>

<style>
/* [class$="--disabled"],
    [class*="--disabled "] * {
        color: #000 !important;
    } */
</style>
